import { useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import TicketMockup from "../components/TicketMockup";

const data = {
  first_name: "Youcef",
  last_name: "Boubkeur",
  email: "youcef_bkr@yahoo.fr",
  birthday: "1982-02-01",
  gender: "male",
  adress: "Sonilac 03 rue",
  telephone: 6589194,
  nin: 1591554,
  id_user: 1,
  id_ticket: 1,
  orderNumber: "TpLm8hoD11",
};

const Test = () => {
  const [file, setFile] = useState("");
  const [tournament_name_ar, setTournament_name_ar] = useState("وداد تلمسان");
  const [tournament_name_fr, setTournament_name_fr] = useState("Widad tlemcen");
  const [tournament_name_en, setTournament_name_en] = useState("Widad Tlemcen");
  const [id_sport, setIdSport] = useState(2);
  const { search } = useLocation();
  const formFile = useRef(null);
  const query = new URLSearchParams(search);
  // Buy Ticket
  const handleOnSubmitBuyTicket = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("http://localhost:4000/booking", data);
      console.log(response);
      window.location = response.data.formUrl;
    } catch (err) {
      console.log(err);
    }
  };
  // Confirm
  const handleOnSubmitConfirmTicket = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "http://localhost:4000/booking/checkout",
        {
          udf1: query.get("udf1"),
          orderId: query.get("orderId"),
          orderNumber: query.get("orderNumber"),
        }
      );
      console.log(response);
    } catch (err) {
      console.log("Confirm error", err);
    }
  };

  // Upload file

  const uploadFileHandler = async (e) => {
    e.preventDefault();
    console.log(formFile);
    const data = new FormData(formFile.current);
    data.append("file", file);

    try {
      const response = await axios.post(
        "http://localhost:4000/club/create",
        data
      );
      console.log(response);
    } catch (err) {
      console.log("Confirm error", err);
    }
  };

  //Handle Change file
  const handleChangeFile = (e) => {
    setFile(e.target.files[0]);
  };
  return (
    <div>
      <form onSubmit={uploadFileHandler} ref={formFile}>
        <input type="text" name="club_name_ar" value={tournament_name_ar} />
        <input type="text" name="club_name_fr" value={tournament_name_fr} />
        <input type="text" name="club_name_en" value={tournament_name_en} />
        <input type="text" name="id_sport" value={id_sport} />
        <input type="file" name="file" onChange={handleChangeFile} />
        <button>Confirm</button>
      </form>
      <form onSubmit={handleOnSubmitBuyTicket}>
        <button>Acheter un ticket!</button>
      </form>

      <form onSubmit={handleOnSubmitConfirmTicket}>
        <button>Confirm Ticket</button>
      </form>
      <TicketMockup />
    </div>
  );
};

export default Test;
