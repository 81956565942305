import { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Navbar = () => {
  const [showNavBoxShadow, setShowNavBoxShadow] = useState(false);
  const navigationBarRef = useRef();
  const classesNav = showNavBoxShadow
    ? "navigation nav-animate-down nav-light"
    : "navigation";

  const onScrollHandler = () => {
    if (window.pageYOffset > navigationBarRef.current.scrollHeight / 2 + 100) {
      setShowNavBoxShadow(true);
    } else {
      setShowNavBoxShadow(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", onScrollHandler);
    return () => window.removeEventListener("scroll", onScrollHandler);
  }, []);

  return (
    <div className={classesNav} ref={navigationBarRef}>
      <div className="container">
        <nav>
          <div className="wrapperLeft">
            <div className="logo">LOGO-STADIUM</div>
            <div className="links">
              <ul>
                <li>
                  <a href="#">Acceuil</a>
                </li>
                <li>
                  <a href="#">Contact</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="wrapperRight">
            <div className="links">
              <ul>
                <li>
                  <a className="link-style-2" href="#">
                    Connecter
                  </a>
                </li>
                <li>
                  <a className="link-style-1" href="#">
                    Inscription
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
