const Fixture = () => {
  return (
    <div className="ui-matchday fixture">
      <div className="wrap fixture__wrap">
        <div className="away-club fixture__home-club">
          <img
            width="160"
            height="204"
            src="https://f.top4top.io/p_1689goksh4.png"
            alt=""
          />
        </div>
        <div className="info fixture__info">
          <div className="date fixture__wrap-date">
            <div class="info-date fixture__date">
              Jeudi, Septembre 30, 2022 | 18:00 H
            </div>
          </div>
          <div className="match fixture__match">
            <div className="column-35 fixture__column-left">
              <div class="teamNameLeftWrapper heading-font fixture__wrap-text-home-club">
                <div class="stm-latest-result-team fixture__text-home-club">
                  <h3>Widad Tlemcen</h3>
                </div>
              </div>
            </div>
            <div className="column-30 fixture__column-center">
              <div class="stmVSWrapp heading-font fixture__versus">VS</div>
            </div>
            <div className="column-35 fixture__column-right">
              <div class="teamNameLeftWrapper heading-font fixture__wrap-text-home-club">
                <div class="stm-latest-result-team fixture__text-home-club">
                  <h3>Mouloudia d'Oran</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="stadium fixture__stadium">
            <div className="info-stadium fixture__text-stadium">Akid Lotfi</div>
          </div>
        </div>

        <div className="home-club fixture__away-club">
          <img
            width="160"
            height="204"
            src="https://l.top4top.io/p_1689c7xxi1.png"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Fixture;
