import "./ticketMockup.css";
const TicketMockup = () => {
  return (
    <div className="ticket">
      <div className="information">
        <h1>Widad Tlemcen VS Mouloudia d'Oran</h1>
        <h2>Championnat d'Algerie Division 1</h2>
        <h3>Dimanche, 17 Juillet 2022 14:30</h3>
        <h4>Stade Akid Lotfi Tlemcen</h4>
        <h4>Place: Gradin</h4>
        <h4>Youcef boubkeur</h4>
        <h4>nin: 149920199299628</h4>
      </div>
      <div className="qrCode">
        <img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGwAAABsCAYAAACPZlfNAAAAAklEQVR4AewaftIAAAPHSURBVO3Bu21dUQxFweWN2xQbYB2uk4lDdkU5JRQc4UBPHwKc+fP27+8bawyxRhFrFLFGeXhHbnyniuREbnQVyU+SG9+pIunEGkWsUcQa5eEDFckryY2vJDdOKpITudFVJCcVySvJjROxRhFrFLFGebgkN25UJDfkxoncOKlIfpLcuFGR3BBrFLFGEWuUh1+uIjmRG53c6CqSTm5MJtYoYo0i1igPv0xF0smNk4qkkxsnFUknNyYRaxSxRhFrlIdLFclkcqOrSF6pIvlKYo0i1ihijfLwAbnxneRGV5F0cuMryY2uIjmRG99JrFHEGkWsUR7eqUh+UkXym1UkP0msUcQaRaxRHj5JbpxUJJ3cOKlIOrnRVSSd3Ogqkhtyo6tIXklunFQkN8QaRaxRxBrl4QNy40ZFclKRdHKjkxtdRXJSkXRyo6tIXkludBVJJzdOKpLPEGsUsUYRa5SHd+RGV5F0cqOrSG7IjZOK5ERu/KSKpJMbJxVJJzduVCSdWKOINYpYozxcqkhO5EZXkXQVyYnc6CqSk4qkkxs3KpJObpxUJCcVSSc3blQkJ2KNItYoYo3y8AG58Upy46Qi6eRGV5GcVCSd3OgqkhsVyWdUJK8k1ihijSLWKA+XKpITudHJja4i6eTGSUXSyY2uIunkxleSG11F0smNriJ5JbFGEWsUsUZ5+CS50VUkndzo5MaJ3Ogqkq4i6eRGV5F0cqOTG11F8hlyo6tITuRGV5F0cqOrSDqxRhFrFLFG+fP27+8bjdzoKpIbcqOrSG7IjRsVSSc3uorkRG50FUknN75SRXJDrFHEGkWsUR4uyY2TiuREbnQVyY2K5JUqkk5uvFJF0smNzxBrFLFGEWuUh3cqkpOK5DMqkhsVSSc3uorkRG50FUknN04qkhty44bc6CqSE7FGEWsUsUZ5eEdufKeK5ERudBVJJze+k9zoKpITudFVJJ3c6ORGV5F0Yo0i1ihijfLwgYrkleTGSUXyShXJSUXSyY2TiuQniTWKWKOINcrDJblxoyK5ITdOKpKuIunkxoncuCE3vlJFckOsUcQaRaxRHoaRG11F0lUkndzoKpJObpxUJJ3c6CqSG3Kjq0g6udFVJJ1Yo4g1ilijPPwyFcmJ3LhRkXRy46QiuSE3TiqSE7lxQ6xRxBpFrFEeLlUk30lunMiNriI5qUhO5EZXkXQVSSc3TuTGK4k1ilijiDXKwwfkxm9WkXRy40ZF0lUkndzoKpKuIjmRG11FciI3TsQaRaxRxBrlz9u/v2+sMcQaRaxRxBrlP8WEf2LunaO2AAAAAElFTkSuQmCC"
          alt=""
        />
      </div>
    </div>
  );
};

export default TicketMockup;
