import MatchDay from "../components/home/matchdaySection/MatchDay";
import Navbar from "../components/UI/navbar/Navbar";

const Home = () => {
  return (
    <div className="home">
      <Navbar />
      <MatchDay />
    </div>
  );
};

export default Home;
