import Navbar from "../../UI/navbar/Navbar";
import Fixture from "../../UI/fixture/Fixture";
import Timer from "../../UI/Timer";
const MatchDay = () => {
  return (
    <>
      <div className="image-stadium-overlay"></div>
      <div className="overlay">
        <div>
          <h1 className="upcoming-match-text">Match a venir</h1>
          <h2 className="tournament-text">CHAMPIONNAT D'ALGERIE DIVISON 1</h2>
        </div>
        <Fixture />
        <Timer />
        <div>
          <button className="btn buy-ticket-btn">Acheter un ticket</button>
        </div>
      </div>
    </>
  );
};

export default MatchDay;
