const Timer = () => {
  return (
    <div className="timer">
      <div className="days">
        <span>18</span>
        <span>Jours</span>
      </div>
      <div className="hours">
        <span>12</span>
        <span>Heures</span>
      </div>
      <div className="minutes">
        <span>45</span>
        <span>minutes</span>
      </div>
      <div className="seconds">
        <span>32</span>
        <span>seconde</span>
      </div>
    </div>
  );
};

export default Timer;
